export default {
    "meta": {
        "title": "İstanbul Halkekmek A.Ş.",
        "description": "İHE E-Sipariş Sitesi",
        "keywords": "İHE,İstanbul,Halk,Ekmek,Sipariş,Esipariş",
        "other": []
    },
    "routes": {
        "url_key": "c002",
        "default": "/c002/trade",
        "change_password": "",
        "user_profile": "",
        "user_settings": "c002/usersettings"
    },
    "defaults": {
        "language": "tr",
        "layout": "centered",
        "scheme": "light",
        "theme": "ihe2"
    },
    "auth": {
        "style": "modern-reversed",
        "signin": {
            "anonymous": true,
            "ask": "email",
            "captcha": {
                "ask": true
            }
        },
        "reset": {
            "ask": "email",
            "otp": {
                "ask": true,
                "content": ""
            }
        },
        "signup": {
            "allowed": true,
            "captcha": {
                "ask": true
            },
            "otp": {
                "ask": true,
                "content": ""
            },
            "company": {
                "ask": false
            },
            "contract": {
                "ask": true,
                "content": ""
            },
            "privacy": {
                "ask": true,
                "content": ""
            }
        }
    },
    "tools": {
        "settings": true
    },
    "theme": {
        "languages": [
            "tr"
        ],
        "colors": [
            {
                "id": "ihe1",
                "name": "İHE Turuncu",
                "primary": "#de6f26"
            },
            {
                "id": "ihe2",
                "name": "İHE Bordo",
                "primary": "#9A3324"
            }
        ],
        "schemes": [
            "auto",
            "light",
            "dark"
        ],
        "layouts": [
            "classy",
            "centered"
        ],
        "header": {
            "transparent": true,
            "background_image": ""
        }
    },
    "modules": [
        "ihe_b2c"
    ],
    "code": "ihe_b2c",
    "publish_key": "623a325e-70cf-4d1f-bebc-1d74cd60667b",
    "menu": [
        {
            "id": "ihe_b2c_trade",
            "type": "collapsable",
            "position": "menu-button",
            "icon": "heroicons_outline:color-swatch",
            "children": [
                {
                    "id": "ihe_b2c_trade_all",
                    "link": "/c002/trade"
                },
                {
                    "id": "ihe_b2c_trade_1000",
                    "link": "/c002/trade/1000"
                },
                {
                    "id": "ihe_b2c_trade_1005",
                    "link": "/c002/trade/1005"
                },
                {
                    "id": "ihe_b2c_trade_1001",
                    "link": "/c002/trade/1001"
                },
                {
                    "id": "ihe_b2c_trade_1004",
                    "link": "/c002/trade/1004"
                },
                {
                    "id": "ihe_b2c_trade_1009",
                    "link": "/c002/trade/1009"
                },
                {
                    "id": "ihe_b2c_trade_1006",
                    "link": "/c002/trade/1006"
                }
            ]
        },
        {
            "id": "ihe_b2c_orders_open",
            "position": "menu-button",
            "link": "/c002/orders",
            "icon": "heroicons_outline:shopping-bag",
            "guard": "EnabledUser"
        },
        {
            "id": "ihe_b2c_basket",
            "position": "button",
            "link": "/c002/basket",
            "icon": "heroicons_outline:shopping-cart"
        },
        {
            "id": "ihe_b2c_shop",
            "position": "button",
            "link": "/c002/shop",
            "icon": "heroicons_solid:location-marker"
        },
        {
            "id": "ihe_b2c_prereturn",
            "position": "button",
            "link": "/c002/askaccount",
            "icon": "heroicons_outline:receipt-refund",
            "guard": "Anonymous"
        },
        {
            "id": "ihe_b2c_usersettings",
            "position": "menu-button",
            "icon": "heroicons_solid:adjustments",
            "guard": "EnabledUser",
            "link": "/c002/usersettings"
        }
    ]
}